.bulk-editor-title {
  color: #000000;
  padding: 20px 20px 10px;
}

.bulk-editor-days {
  display: flex;
}

.bulk-editor-days .day-of-week {
  flex-grow: 1;
  border: 1px solid #e4eaec;
}

.bulk-editor-days .day-of-week h3 {
  color: #000000;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  padding: 10px 0;
}

.bulk-editor-days .day-of-week.selected h3 {
  color: #0074cc;
  font-weight: 600;
}

.bulk-editor-wrapper {
  display: flex;
  height: calc(100% - 107px);
}

.bulk-editor-sidebar {
  width: 40%;
  height: 100%;
  border-right: 1px solid #e4eaec;
}

.bulk-editor {
  width: 60%;
}

.create-schedule-button h3 {
  padding: 20px 0;
}

.bulk-editor-sidebar {
  width: 40%;
  border-right: 1px solid #e4eaec;
}

.bulk-editor-sidebar .option {
  padding: 10px;
  border-bottom: 1px solid #e4eaec;
  transition: 0.2s ease-in;
  display: flex;
  cursor: pointer;
}

.bulk-editor-sidebar .option:hover {
  background-color: #e4eaec;
}

.bulk-editor-sidebar .option p {
  color: #000000;
  font-size: 14px;
  width: 80%;
}

.bulk-editor-sidebar .option .delete-button {
  width: 20%;
}

.delete-button .delete-icon {
  width: 32px;
  height: 32px;
  background: url(/public/delete-icon.png);
}

.bulk-editor-sidebar .option span {
  display: block;
  color: #76838f;
  padding-left: 5px;
  padding-top: 2px;
}

.bulk-editor-sidebar .option.selected p {
  color: #0074cc;
  font-weight: 700;
}

.create-schedule-button, .bulk-editor-sidebar .create-schedule-button:hover {
  background-color: #0074cc;
}

.bulk-editor {
  width: 60%;
}

.bulk-form {
  padding: 20px;
}

.bulk-form-wrapper {
  display: flex;
  justify-content: space-around;
}

.bulk-form-wrapper label {
  display: flex;
  align-items: center;
}

.bulk-form-wrapper h5 {
  font-size: 18px;
  font-weight: 300;
  color: #000000;
}

.bulk-form-wrapper input {
  margin-left: 5px;
}