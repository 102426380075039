.toast-container {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  background-color: #333;
  color: #fff;
  border-radius: 5px;
  padding: 10px 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.toast-message {
  flex: 1;
  margin-right: 10px;
}

.toast-close-button {
  border: none;
  background: none;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  padding: 0;
}