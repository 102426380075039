.close-button {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.close-button .cross-1 {
  height: 25px;
  width: 2px;
  margin-left: 12px;
  background-color: black;
  transform: rotate(45deg);
  Z-index: 1;
}

.close-button .cross-2 {
  height: 25px;
  width: 2px;
  background-color: black;
  transform: rotate(90deg);
  Z-index: 2;
}