.day {
  border: 1px solid #e4eaec;
  padding: 10px;
  font-size: 12px;
  color: #e4eaec;
  height: 100px;
  vertical-align: top;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.day:hover {
  background-color: #e4eaec;
}

.calendar-day {
  color: #000000;
  font-size: 12px;
  font-weight: 400;
}

.schedules {
  color: #000000;
}

.schedule {
  font-size: 12px;
}

.schedule-line {
  padding-top: 10px;
}

.schedule-line span {
  color: #76838f;
  padding-left: 5px;
}

.selected {
  background-color: #e4eaec;
}

.selected .calendar-day {
  color: #0074cc;
  font-weight: 700;
}