.back-button {
  display: block;
  width: 200px;
  text-decoration: none;
}

.back-button .back {
  height: 30px;
  margin: 10px auto;
  display: block;
}

.seat-visualizer {
  display: flex;
  width: calc(2880px + 200px);
  height: 100vh;
  overflow: auto;
  border-top: 1px solid #e4eaec;
}

.seat-sidebar {
  width: 200px;
  height: 100vh;
  background-color: white;
  z-index: 1;
  border-right: 1px solid #e4eaec;
  position: fixed;
  overflow-y: auto;
}

.seat-sidebar .seat-sidebar-header {
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #e4eaec;
}

.seat-sidebar .seat-sidebar-header .date-selector-wrapper {
  display: block;
  padding: 4.5px 0;
}

.seat-sidebar .seat-sidebar-header input[type="date"] {
  width: 120px;
  height: 30px;
  margin: auto;
  display: block;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.seat-sidebar .seat {
  border-bottom: 1px solid #e4eaec;
  height: 80px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.seat-sidebar .seat .edit-button {
  display: none;
  cursor: pointer;
}

.seat-sidebar .seat:hover .edit-button {
  display: block;
}

.seat-sidebar .seat .edit-button .edit-icon {
  background: url(/public/pencil.png);
  width: 24px;
  height: 24px;
  background-size: contain;
}

.seat-sidebar .seat .delete-button {
  display: none;
  transition: 0.3 ease-in;
  cursor: pointer;
}

.seat-sidebar .seat:hover .delete-button {
  display: block;
}

.seat-sidebar .seat:hover .delete-button .delete-icon {
  width: 26px;
  height: 26px;
  background-size: contain;
}

.seat-sidebar .seat-create {
  position: relative;
  cursor: pointer;
  transition: 0.3s ease-in;
}

.seat-sidebar .seat-create:hover {
  background-color: #0074cc;
  color: white;
}

.seat-sidebar .seat-create h4 {
  color: #0074cc !important;
}

.seat-sidebar .seat-create:hover h4 {
  color: #ffffff !important;
}

.seat-sidebar .seat-create .create-button .cross-1, 
.seat-sidebar .seat-create .create-button .cross-2 {
  background-color: #0074cc;
}

.seat-sidebar .seat-create:hover .create-button .cross-1, 
.seat-sidebar .seat-create:hover .create-button .cross-2 {
  background-color: #ffffff;
}

.seat-sidebar .seat h4 {
  font-size: 22px;
  font-weight: 300;
  color: black;
  padding: 27.5px 0;
  text-align: center;
}

.seat-appointments {
  position: absolute;
  top: 50px;
  left: 200px;
  min-height: 100vh;
  width: 2880px;
  overflow-x: auto;
  z-index: 0;
}

.seat-appointments .seat-appointments-header {
  height: 50px;
  width: 100%;
  border-bottom: 1px solid #e4eaec;
  display: block;

}

.seat-appointments .seat-appointments-header .hour-header {
  font-size: 20px;
  font-weight: 300;
  padding: 15px 0;
  width: 119px;
  border-right: 1px solid #e4eaec;
  float: left;
}

.seat-appointments .seat-appointments-header .hour-header h5 {
  text-align: center;
}

.seat-appointments .timeline {
  border-bottom: 1px solid #e4eaec;
  height: 80px;
  position: relative;
  border-bottom: 1px solid #e4eaec;
}

.seat-appointments .timeline .appointment {
  position: absolute;
  height: 55px;
  background-color: #0074cc;
  border-radius: 5px;
  padding: 5px 0;
  margin: 7.5px 0;
  cursor: pointer;
}

.seat-appointments .timeline .appointment h5, 
.seat-appointments .timeline .appointment h6 {
  color: white;
  padding-left: 5px;
}

.seat-appointments .timeline .appointment h5 {
  padding-bottom: 5px;
}

.seat-appointments .timeline .appointment h6 {
  padding-left: 6px;
  font-style: italic;
}
