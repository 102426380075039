.calendar-list {
  border: 1px solid #e4eaec;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%);
  width: 60%;
  min-width: 500px;
  margin: 50px auto;
  padding-bottom: 10px;
}

.calendar-list-header {
  display: flex;
  width: 80%;
  padding: 30px 0;
  margin: 0 auto;
  justify-content: space-between;
}

.calendar-list h2 {
  font-size: 24px;
  font-weight: 300;
  width: 200px;
}

.calendar-list .calendar {
  width: 80%;
  margin: 15px auto;
  border-top: 1px solid #e4eaec;
  padding-top: 15px;
  display: flex;
  align-items: center;
}

.calendar-list .calendar h3 {
  width: 50%;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.calendar-list .calendar .button-wrapper {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
}

.calendar-list .calendar .delete {
  color: #d11a2a;
  border-color: #d11a2a;
}

.calendar-list .calendar .delete:hover {
  background-color: #d11a2a;
  color: white;
}

.proctor-location-list {
  border: 1px solid #e4eaec;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%);
  width: 60%;
  min-width: 500px;
  margin: 50px auto;
  padding-bottom: 10px;
}

.proctor-location-list-header {
  display: flex;
  width: 80%;
  padding: 30px 0;
  margin: 0 auto;
  justify-content: space-between;
}

.proctor-location-list h2 {
  font-size: 24px;
  font-weight: 300;
  width: 200px;
}

.proctor-location-list .proctor-location {
  width: 80%;
  margin: 15px auto;
  border-top: 1px solid #e4eaec;
  padding-top: 15px;
  display: flex;
}

.proctor-location-list .proctor-location h3 {
  width: 50%;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

.proctor-location-list .proctor-location .button-wrapper {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
}