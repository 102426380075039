.sid {
  font-size: 8px;
  color: #ffffff;
  padding: 5px;
}

.schedule-form {
  display: block;
  height: calc(100% - 20px);
  padding-top: 20px;
}

.schedule-form label {
  display: block;
  padding: 10px;
}

.schedule-form label h5 {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
}

.schedule-form label input {
  margin-top: 10px;
  border: 1px solid #e4eaec;
  padding: 10px 10px;
}

.schedule-form input[type="number"] {
  width: 50px;
}

.schedule-form label p {
  display: inline-block;
  font-size: 12px;
  padding-left: 10px;
  font-style: italic;
  width: calc(100% - 82px);
  padding-top: 10px;
}

.schedule-form .flex-container {
  display: flex;
  align-items: center;
}

.schedule-form button[type="submit"] {
  bottom: 10px;
  right: 10px;
  position: absolute;
  cursor: pointer;
}

.schedule-form .start-time, .schedule-form .end-time {
  flex-grow: 1;
  padding-top: 0;
}