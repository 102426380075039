table {
    border-collapse: collapse;
    width: 100%;
}

th {
    text-align: left;
    padding: 8px 10px;
    width: calc(100% / 7);
}

tbody {
    border: 1px solid #e4eaec;
}

td {
    width: calc(100% / 7);
}