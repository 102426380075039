.calendar-day-top-bar {
  border-bottom: 1px solid #e4eaec;
}

.calendar-day-top-bar h3 {
  padding: 14px 41px 14px 10px;
  color: #000000;
  font-weight: 300;
  font-size: 22px;
}

.calendar-day-editor {
  display: flex;
  align-items: stretch;
  height: 90%;
}

.schedule-picker-sidebar {
  width: 40%;
  border-right: 1px solid #e4eaec;
}

.schedule-picker-sidebar .option {
  padding: 10px;
  border-bottom: 1px solid #e4eaec;
  transition: 0.2s ease-in;
  display: flex;
  cursor: pointer;
}

.schedule-picker-sidebar .option:hover {
  background-color: #e4eaec;
}

.schedule-picker-sidebar .option p {
  color: #000000;
  font-size: 14px;
  width: 80%;
}

.schedule-picker-sidebar .option .delete-button {
  width: 20%;
}

.delete-button .delete-icon {
  width: 32px;
  height: 32px;
  background: url(/public/delete-icon.png);
}

.schedule-picker-sidebar .option span {
  display: block;
  color: #76838f;
  padding-left: 5px;
  padding-top: 2px;
}

.schedule-picker-sidebar .option.selected p {
  color: #0074cc;
  font-weight: 700;
}

.schedule-picker-editor {
  width: 60%;
}

.create-schedule-button, .schedule-picker-sidebar .create-schedule-button:hover {
  background-color: #0074cc;
}

.create-schedule-button {
  cursor: pointer;
}

.create-schedule-button h3 {
  text-align: center;
  padding: 5px 0;
  color: white;
}