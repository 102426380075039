.appointment-details {
  width: 70%;
  margin: 0 auto;
  overflow-y: scroll;
}

.appointment-details h2 {
  padding: 50px 0 30px;
  font-size: 28px;
  font-weight: 300;
  text-align: center;
  color: #000000;
}

.appointment-details h3 {
  padding: 30px 0 20px;
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  color: #000000;
}

.appointment-details h5 {
  padding: 30px 0 20px;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  color: #000000;
}

.appointment-details p {
  padding: 10px 0;
  color: #000000;
}