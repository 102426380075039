.calendar-bar {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 20px;
}

.calendar-title {
  font-size: 20px;
  font-weight: 700;
}

.next, .previous, .bulk-create {
  cursor: pointer;
}

.right-arrow {
  width: 0.4em;
  height: 0.4em;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(45deg);
  margin-right: 0.5em;
}

.left-arrow {
  width: 0.4em;
  height: 0.4em;
  border-right: 0.2em solid black;
  border-top: 0.2em solid black;
  transform: rotate(-45deg);
  margin-right: 0.5em;
}

/* .calendar-title h3 {
  font-size: 20px;
} */