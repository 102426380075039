.seat-editor-wrapper {
  width: 80%;
  margin: 0 auto;
}

.seat-editor-wrapper h2 {
  font-size: 32px;
  font-weight: 300;
  text-align: center;
  padding: 30px 0;
}

.seat-editor-wrapper .seat-editor .seat-name {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.seat-editor-wrapper .seat-editor .seat-name h5 {
  font-size: 18px;
  font-weight: 300;
  padding: 5px;
  color: #000000;
}

.seat-editor-wrapper .seat-editor .seat-name input {
  font-size: 14px;
  padding: 5px;
  color: #000000;
  border: 1px solid #e4eaec;
}

.seat-editor-wrapper .seat-editor button[type="submit"] {
  display: block;
  margin: 0 auto;
}