.create-button {
  width: 25px;
  height: 25px;
}

.create-button .cross-1 {
  height: 25px;
  width: 3px;
  margin-left: 12px;
  background-color: black;
  Z-index: 1;
}

.create-button .cross-2 {
  height: 25px;
  width: 3px;
  background-color: black;
  transform: rotate(90deg);
  Z-index: 2;
}