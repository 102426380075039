.calendar-form {
  width: 50%;
  height: 50%;
  margin: 50px auto;
  border: 1px solid #e4eaec;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 5%);
  border-radius: 5px;
  padding: 10px;
  position: relative;
}

.calendar-form form {
  position: relative;
  width: 70%;
  margin: 0 auto;
}

.calendar-form h2 {
  font-size: 24px;
  font-weight: 300;
  text-align: center;
  padding: 10px 0;
  border-bottom: 1px solid #e4eaec;
  width: 70%;
  margin: 0 auto;
}

.calendar-form h5 {
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  padding: 8px 20px 0 0;
}

.calendar-form label {
  display: flex;
  align-items: center;
  align-content: space-between;
  padding: 10px 0;
}

.calendar-form input {
  margin-top: 10px;
  border: 1px solid #e4eaec;
  padding: 10px 10px;
}

.calendar-form button[type="submit"] {
  display: block;
  margin: 10px auto;
}

.proctor-location-settings h3 {
  text-align: center;
  font-weight: 300;
  width: 70%;
  margin: 0 auto;
  padding: 10px 0;
  border-bottom: 1px solid #e4eaec;
}

.proctor-location-settings .location-calendar {
  display: block;
  width: 70%;
  margin: 10px auto;
}